<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>对账列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div
            class="top-operation-button"
            v-if="is_auth('accountbook.check.reconciliation')"
          >
            <el-button size="medium" type="primary" @click="reconciliation()"
              >对账</el-button
            >
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium">
        <el-table-column
          prop="created_at"
          label="对账时间"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="start_time"
          label="对账周期"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.start_time == '1970-01-01 08:00'">
              开始 - {{ scope.row.end_time }}</span
            >
            <span v-else>
              {{ scope.row.start_time }} - {{ scope.row.end_time }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="group_num" label="团课订单数" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="group_amount" label="团课支付金额" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="camp_num" label="训练营订单数" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="camp_amount" label="训练营支付金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="coach_num" label="私教订单数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="coach_amount" label="私教支付金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="activity_num" label="活动订单数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="activity_amount" label="活动支付金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="commodity_num" label="商品订单数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="commodity_amount" label="商品支付金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="contest_num" label="赛事订单数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="contest_amount" label="赛事支付金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column  prop="final_amount" label="对账总现金额" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page_name: "对账列表",
      loading: true,
      tableData: [], // 列表内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
    };
  },
  // 创建
  created() {
    this.getlist();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    //对账
    reconciliation() {
      let postdata = {
        api_name: "accountbook.bill.generate",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.$message(json.message);
        }
      });
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "accountbook.bill.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
        }
      });
    },
  },
};
</script>
